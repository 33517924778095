<template>
  <Login @get_user_password="login" :login_loading="login_loading">检测人员入口</Login>
</template>

<script>
import Login from "../components/Login";
import { Login as userlogin } from "../axios/api"
import { Toast } from 'vant';
export default {
  name: "TestingLogin",
  /*路由拦截 判断缓存有无用户信息*/
  beforeRouteEnter(to, from ,next) {
    if(localStorage.getItem("token") && localStorage.getItem("identity") && localStorage.getItem("userName")){
      //缓存中存在,默认用户已经登录
      if(localStorage.getItem("identity")==3){
        next("/testingUser")
      }else{
        // 不是检测人员登录
        localStorage.removeItem("token")
        localStorage.removeItem("identity")
        localStorage.removeItem("userName")
        next(true)
      }
    }else{
      next(true)
    }
  },
  components:{
    Login,
  },
  data(){
    return{
      login_loading: false
    }
  },
  methods:{
    login(u,p){
      /*首先判断账户密码是否为空*/
      if(u && p){
        this.login_loading=true
        userlogin({type:"inspector",number:u,password:p}).then(respose=>{
          if(respose.state==='1'){
            Toast.success({message:"登录成功"})
            localStorage.setItem("userName",respose.data.realname);//用户名字
            localStorage.setItem("token",respose.data.token);//用户token
            localStorage.setItem("identity","3");//用户身份
            localStorage.setItem("uid",respose.data.insid)//检测员的id
            this.$router.push("/testingUser")
          }else{
            // 登录失败
            Toast({message:"账户密码错误",icon:"cross"})
          }
          this.login_loading=false
        }).catch(error=>{
          console.log(error)
        })
      }else{
        Toast.fail("请先输入账户密码")
      }
    }
  }
}
</script>
